import { gsap } from "gsap/all"
import Layout from "../layout"
import Menu from "../components/menu"
import Text from "../components/opening/text"
//
import mitt from 'mitt'
window.LOAD_EVENT = mitt()
class Error extends Layout {
    constructor() {
        super()
        this.container = document.querySelector('#error')
        this.status = "loading"
        this.menu = new Menu()
        // const title = document.querySelector("#opening-title")
        // if (title) this.opening = new Opening(title)
        const title = this.container.querySelector('.title')
        this.title = new Text(title, {
            lineWidth: 2
        })
        const subtitle = this.container.querySelector('.subtitle')
        this.subtitle = new Text(subtitle, {
            lineWidth: .5
        })
        this.events()
    }

    events() {
        window.onload = (e) => this.loaded(e)
        window.addEventListener("pageshow",  (e) => this.pageShow(e))
        window.onpopstate = (e) => this.reload(e)
    }

    loaded() {
        if (this.status == "loaded") return false
        this.status = "loaded"
        window.LOAD_EVENT.emit('loaded')
        this.menu.start()
        this.preloader.hide()
        this.title.show()
        gsap.delayedCall(.35, () => {
            this.subtitle.show()
        })
    }

}

const _error = new Error()
window._ERROR_ = _error